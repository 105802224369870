import Currency from '@/constants/currency';
const { ACCEPTED_CURRENCIES, WRONG_CURRENCY, MODEL_SETTINGS, DEFAULT } = Currency;
import { get } from 'lodash';
import store from '@/store';
import { formatCurrency } from '@/utils';
class CurrencyController {
  #className = 'CurrencyController'
  #modelSettings = null;
  /**
   * @param modelSettings
   * @param currency
   */
  constructor({ modelSettings = MODEL_SETTINGS, currency = DEFAULT } = {
  }) {
    this
      .setModelSettings(modelSettings)
      .setCurrentCurrency(currency);
  }
  setModelSettings(modelSettings = MODEL_SETTINGS) {
    this.#modelSettings = modelSettings || MODEL_SETTINGS;
    return this;
  }
  getInfo() {
    return {
      modelSettings: this.#modelSettings,
      currency: this.currency,
    };
  }
  formatCurrency(amount) {
    return formatCurrency(amount, this.currency.toUpperCase());
  }
  /**
   * @param currency
   * @returns {boolean}
   */
  isAcceptedCurrency(currency) {
    return ACCEPTED_CURRENCIES.includes(currency);
  }
  /**
   * Error handler
   * @param errorText
   */
  onError(errorText = '') {
    if (!errorText) return;
    throw new Error(`${this.#className}: ${errorText}`);
  }
  /**
   * Setter for current currency
   * @param currency
   */
  setCurrentCurrency(currency) {
    const currencyToSet = currency || this.currency;
    if (!this.isAcceptedCurrency(currencyToSet)) {
      return this.onError(WRONG_CURRENCY);
    }
    store.commit(this.#modelSettings.mutation, currencyToSet);
    return this;
  }
  /**
   * Not reactive
   * Use mapState or mapGetters
   * Getter for current currency
   * @returns {*}
   */
  get currency() {
    return get(store.state, this.#modelSettings.state);
  }
  /**
   * Get router query params
   * @returns {{currency: *}}
   */
  getQueryParams() {
    return {
      currency: this.currency,
    };
  }
}
const createCurrency = (currencyData) => new CurrencyController(currencyData);
export {
  CurrencyController,
  createCurrency,
};

import store from '@/store';
import UserApi from '@/services/graphql/userProfile';

const CANADA_CODE = 'CA';
const USA_CODE = 'US';
const GEOJS_URL = 'https://get.geojs.io/v1/ip/country.json';

// 2-letter country codes
// https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
let countryCode = null;

/**
 * Tries to determine the country of the user through different methods.
 * - ONLY CARE ABOUT CANADA and NOT CANADA (set to US) for now.
 * - The methods might not care about the exact country if we know is is/isn't CA.
 *
 * Following methods are ordered:
 * 1. Location in User Profile
 * 2. API Call which checks the Cloudfront Country Header.
 * 3. GeoJS - free service.
 * 4. Give up. User is American.
 *
 * If we can get a result from any method, we stop there.
 */
async function fetchCountryCodeOfUser() {
  let code = await getCountryCodeFromUserProfile();
  if (code) return code;

  code = await getCountryCodeFromAPI();
  if (code) return code;

  code = await getCountryCodeFromGeoJS();
  if (code) return code;

  return USA_CODE;
}

// Note: This only cares about Canada/Not Canada!
async function getCountryCodeFromUserProfile() {
  try {
    const profileLoc = await store.getters['UserProfile/getLocation'];
    if (profileLoc && typeof profileLoc === 'string') {
      const tokens = profileLoc.split(',');
      // Some safety so we can filter out nonsense strings.
      if (tokens.length > 1) {
        const country = tokens.pop().trim().toUpperCase();
        return country === 'CANADA' ? CANADA_CODE : USA_CODE;
      }
    }
  } catch (e) {
    console.error(e);
  }
  return null;
}

async function getCountryCodeFromAPI() {
  try {
    const res = await UserApi.getUserCountryCode();
    const code = res.data.code;
    if (code && typeof code === 'string' && code.length === 2) {
      return code;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
}

// Use at the end - unreliable
// - Will be blocked by adblock, rate-limits, etc.
async function getCountryCodeFromGeoJS() {
  try {
    const res = await fetch(GEOJS_URL, {
      method: 'GET',
      mode: 'cors',
    });
    const response = await res.json();
    const country = response.country;
    if (country && typeof country === 'string' && country.length == 2) {
      return response.country;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
}

async function isUserInCanada() {
  if (countryCode == null) {
    const code = await fetchCountryCodeOfUser();
    countryCode = code;
  }
  return countryCode?.toUpperCase() === 'CA';
}

export {
  fetchCountryCodeOfUser,
  isUserInCanada,
};

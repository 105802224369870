import { API } from 'aws-amplify';
import { STRIPE_SUBSCRIPTION } from '@/services/graphql/fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
const SUBSCRIPTION_ID = `
  stripeSubscriptionId,
`;
const STRIPE_PAYMENT_METHOD = `
  brand,
  exp_month,
  exp_year,
  last4,
  payment_id,
`;
export default {
  stripeCreateWorkspaceSubscribe(variables) {
    const query = `
      mutation stripeCreateWorkspaceSubscribe(
        $priceId: String!
        $workspaceId: String!
        $currency: String
        $quantity: Int!
        $paymentMethodId: String!
      ) {
        response: stripeCreateWorkspaceSubscribe (
          priceId: $priceId
          workspaceId: $workspaceId
          quantity: $quantity
          currency: $currency
          paymentMethodId: $paymentMethodId
        ) {
          ${SUBSCRIPTION_ID}
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  listPaymentsMethodForUser() {
    const query = `mutation listPaymentsMethodForUser{
        response:listPaymentsMethodForUser{
          ${STRIPE_PAYMENT_METHOD}
        }
    }`;
    return API.graphql({
      query,
    });
  },
  getWorkspaceSubscription(variables) {
    return new requestBuilder({
      requestName: 'getWorkspaceSubscription',
      requestVariables: {
        workspaceId: 'String!',
      },
      response: STRIPE_SUBSCRIPTION,
      variables,
    }).processRequest();
  },
};

<template>
  <Payment
    is-ws-payment
    title-payment="Choose Your Workspace Type"
    :used-licenses="usedLicenses"
    :user-subscription="currentWorkspaceSubscription" />
</template>
<script>
import Payment from '@/views/Payment';
import {
  mapGetters, mapMutations, mapState,
} from 'vuex';
import WorkspacePaymentApi from '@/services/graphql/workspacePayment';
export default {
  name: 'WrapperWorkspacePayment',
  components: {
    Payment,
  },
  data() {
    return {
      workspaceSubscriptions: [],
    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('Workspace', ['getActiveWorkspace']),
    usedLicenses() {
      return this.getActiveWorkspace?.usedLicenses || 0;
    },
    currentWorkspaceSubscription() {
      const sub = this.workspaceSubscriptions.find(s => s?.status === 'active');
      return sub;
    },
  },
  async created() {
    this.spinner(true);
    await this.getWorkspaceSubscription();
    this.spinner(false);
  },
  methods: {
    ...mapMutations(['spinner']),
    async getWorkspaceSubscription() {
      try {
        const { data } = await WorkspacePaymentApi.getWorkspaceSubscription({
          workspaceId: this.activeWorkspaceId,
        });
        this.workspaceSubscriptions = data?.response || [];
      } catch (err) {
        console.log('getWorkspaceSubscription err', err);
      }
    },
  },
};
</script>
<style scoped>
</style>
